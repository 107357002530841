<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">Inactivity Advertisement</div>
          <button
            v-if="isAuthorized(permissions.app_setting_create)"
            class="btn btn-sm btn-primary p-2 btn-resize-standard"
            @click="handleShowHideAction"
          >
            <i class="icon-add" />
            Add New
          </button>
        </div>
        <div class="card-body">
          <div class="offer-page-content" v-loading="state.loading">

            <ItemTable
              :columns="state.columnMap"
              :data="state.ads"
              :show-actions="isAuthorized(permissionsGroup.appSetting)"
              no-data-msg="No Ads Available"
            >
              <template #media="{item}">
                <div class="d-flex align-items-center">
                  <span>{{ item.media }}</span>
                  <span
                    v-if="item.type === 'video'"
                    class="badge badge-white pe-0"
                  >
                  <i
                    :class="`icon-volume-${item.with_sound ? '2' : 'x text-secondary'} fs18`"
                  />
                </span>

                  <a
                    target="_blank"
                    :href="getLink(item)"
                  >
                    <i class="icon-open_in_new fs20 text-primary ms-1" />
                  </a>
                </div>
              </template>

              <template #status="{item}">
                <span v-html="renderEnabledBadge(item.status)" />
              </template>

              <template #action="{item}" v-if="isAuthorized(permissionsGroup.appSetting)">
                <EditButton
                  v-if="isAuthorized(permissions.offer_update)"
                  @click="handleSelectedAdAction(item, LIST_ITEM_ACTION_UPDATE)"
                />

                <DeleteButton
                  v-if="isAuthorized(permissions.offer_delete)"
                  @click="handleSelectedAdAction(item, LIST_ITEM_ACTION_DELETE)"
                />
              </template>

            </ItemTable>
          </div>
        </div>
      </div>
    </div>

    <InactivityAdAction
      :show="state.showAction"
      :itemToUpdate="state.selectedItem"
      @hide="handleShowHideAction"
      @updated="handleShowHideAction"
    />
  </div>
</template>

<script>
import InactivityAdAction from '@/components/InactivityAd/InactivityAdAction';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';
import Toaster from '@/utils/Toaster';
import { currency } from '@/Mixins/appHelper';
import { isAuthorized, renderEnabledBadge } from '@/utils/Helper';
import permissions, { permissionsGroup } from '@/utils/permissions';
import { LIST_ITEM_ACTION_DELETE, LIST_ITEM_ACTION_UPDATE } from '@/utils/constants';
import SweetAlert from '@/utils/SweetAlert';
import ItemTable from '@/components/Util/ItemTable';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import { STORAGE_FULL_URL } from '@/utils/Urls';

export default {
  name: 'InactivityAdPage',
  components: { DeleteButton, EditButton, ItemTable, InactivityAdAction },

  setup () {
    const store = useStore();

    const state = reactive({
      columnMap: [
        { field: 'type', header: 'Type', sortable: true },
        { field: 'duration', header: 'Duration', sortable: true },
        { field: 'media', header: 'Media' },
        { field: 'sort', header: 'Sort', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
      ],

      ads: computed(() => store.getters['inactivityAd/ads']),
      loading: computed(() => store.state.inactivityAd.loading),
      selectedItem: {},
      showAction: false,

    });

    const deleteAd = async () => {

      try {
        let response = await store.dispatch('inactivityAd/deleteAd', state.selectedItem.id);

        Toaster.successAlt({
          message: response.data.message || 'Ad Successfully Deleted'
        });

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong'
        });

      }
    };

    const handleSelectedAdAction = (item, action) => {

      if (!isAuthorized(permissionsGroup.appSetting)) return;

      state.selectedItem = item;

      if (action === LIST_ITEM_ACTION_DELETE) {

        if (!isAuthorized(permissions.app_setting_delete)) return;

        SweetAlert.confirmError({
          text: 'This will permanently Delete this Ad',
          preConfirm: deleteAd
        });

        return;
      }
      state.showAction = true;
    };

    const handleShowHideAction = () => {
      state.selectedItem = {};
      state.showAction = !state.showAction;
    };

    const getLink = (item) => {
      if (item.type === 'img') {
        return `${STORAGE_FULL_URL}/inactivityAd/${item.media}`;
      }

      return `https://www.youtube.com/watch?v=${item.media}`;
    };

    return {
      state,
      handleShowHideAction,
      currency,
      renderEnabledBadge,
      handleSelectedAdAction,
      LIST_ITEM_ACTION_DELETE,
      LIST_ITEM_ACTION_UPDATE,
      permissions,
      permissionsGroup,
      isAuthorized,
      getLink,
    };
  },
};
</script>

<style scoped>

</style>
